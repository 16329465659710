import { jsxs as _, jsx as i } from "react/jsx-runtime";
import { useRef as b, useMemo as R, useEffect as k } from "react";
import { ScrollContainer as w } from "@vgno/core";
import { classnames as y } from "@vgno/utils";
import { getStoriesOverlay as z } from "@vgno/video";
import '../assets/VideoCarousel.css';const E = "_video_1mxmj_1", C = "_highlight_1mxmj_14", M = "_image_1mxmj_21", O = "_title_1mxmj_28", S = "_label_1mxmj_37", c = {
  video: E,
  highlight: C,
  image: M,
  title: O,
  label: S
}, L = ({
  className: j,
  format: o = "vertical",
  isIOS: I = !1,
  title: d,
  videos: n,
  ...x
}) => {
  const h = b(null), u = b([]), m = R(() => o === "vertical" ? n == null ? void 0 : n.filter(
    (t) => t.status === "active" && t.additional.metadata.aspectRatio === "0.56"
  ) : n, [o, n]);
  k(() => {
    const t = new IntersectionObserver(([r]) => {
      var e;
      if (!r.isIntersecting)
        return;
      t.disconnect();
      const s = m.map((a) => a.url).filter((a) => a.startsWith("https://www.vg.no/stories/"));
      (e = z()) == null || e.preloadItems(s);
    });
    return t.observe(h.current), () => t.disconnect();
  }, [m]), k(() => {
    const t = new IntersectionObserver(
      (r) => {
        r.forEach((s) => {
          const e = s.target;
          e instanceof HTMLImageElement || (s.isIntersecting && e.dataset.src && (e.src = e.dataset.src, delete e.dataset.src), s.isIntersecting && s.intersectionRatio >= 0.95 ? e.play() : e.pause());
        });
      },
      {
        rootMargin: "0px",
        threshold: [0, 0.95]
      }
    );
    return u.current.forEach((r) => {
      r && t.observe(r);
    }), () => t.disconnect();
  }, [m]);
  const p = (t, r) => {
    u.current[r] = t;
  };
  return /* @__PURE__ */ _(
    "section",
    {
      className: y("font-inter", j),
      "data-track-element-type": "Vertical Video Carousel",
      "data-track-primary-position": 1,
      ...x,
      ref: h,
      children: [
        d && /* @__PURE__ */ i(
          "h2",
          {
            className: y(
              "font-weight-semi-bold title-large",
              c.label
            ),
            children: d
          }
        ),
        /* @__PURE__ */ i(w, { scrollBy: (o === "horizontal" ? 300 : 200) + 16, children: m.map((t, r) => {
          var g, v;
          const { preview_vivi_category: s } = t.additional.metadata, e = t.imageAsset, a = ((g = e == null ? void 0 : e.urls) == null ? void 0 : g[4].url) || ((v = e == null ? void 0 : e.urls) == null ? void 0 : v[0].url), f = s || e == null ? void 0 : e.urls.map(({ url: l, width: N }) => `${l} ${N}w`).join(", ");
          return /* @__PURE__ */ i("li", { className: c.video, children: /* @__PURE__ */ _(
            "a",
            {
              "data-track-secondary-position": r + 1,
              "data-track-id": `teaser:${t.id}`,
              "data-track-name": t.title,
              "data-track-target-newsroom": t.provider,
              "data-track-image-url": a,
              "data-track-click-intent": "Watch",
              "data-track-impression": !0,
              href: t.url,
              children: [
                I || !s ? /* @__PURE__ */ i(
                  "img",
                  {
                    alt: "",
                    className: c.image,
                    fetchpriority: "low",
                    loading: "lazy",
                    ref: (l) => p(l, r),
                    src: s ?? a,
                    ...f && {
                      sizes: o === "horizontal" ? "300" : "200",
                      srcSet: f
                    }
                  }
                ) : /* @__PURE__ */ i(
                  "video",
                  {
                    className: c.image,
                    "data-src": s,
                    disableRemotePlayback: !0,
                    loop: !0,
                    muted: !0,
                    playsInline: !0,
                    poster: a,
                    ref: (l) => p(l, r)
                  }
                ),
                /* @__PURE__ */ i("h3", { className: c.title, children: /* @__PURE__ */ i("span", { className: c.highlight, children: t.title }) })
              ]
            }
          ) }, t.id);
        }) })
      ]
    }
  );
};
export {
  L as V
};
